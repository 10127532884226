<template>
  <div class="site">
    <main class="site-content is-flex is-flex-direction-column">
      <section class="roomHeader has-background-primary" :style="backgroundImage">
        <div class="exit-button mr-2" @click="GoToMenu()">
          <d-icon icon="FaArrowLeft" />
        </div>
        <h5 class="title pb-2 is-size-5">{{$t('event.photofeed')}}</h5>
      </section>
      <!-- Componente PhotoFeedSingle funciona como una vista children -->
      <router-view />

      <section v-if="!showUploadModal" class="photofeed" ref="photofeed">
        <div v-if="!loading && media.length === 0" class="p-4">
          <h6 class="is-size-6 has-text-weight-bold mb-2">
            {{$t('event.galleryEmpty')}}
          </h6>
          <p class="is-size-7">
            {{$t('event.uploadPrompt')}}
          </p>
        </div>

        <div class="photofeed-items p-2">
          <div class="add-image-container" @click="handleAddImage">
            <div class="add-image-button p-4">
              <d-icon icon="FaPlus" class="plus-icon" size />
              <label>{{$t('event.addImage')}}</label>
            </div>
          </div>

          <!-- Plceholders  si no llega a un mìnimo de imagenes -->
          <template v-if="media.length === 0">
            <div v-for="i in 14" :key="i" class="photofeed-empty-image">
              <d-icon icon="FaImage" size="is-small" />
            </div>
          </template>

          <div v-for="item in media" :key="item.id" @click="openImage(item.id)" class="photofeed-item">
            <img :src="GetItemThumbnail(item)" :alt="item.media.description" />
            <div class="play-icon" v-if="item.media.relationTo == 'user-videos'">
              <d-icon icon="FaPlay" size="large" />
            </div>
          </div>
        </div>

        <input type="file" accept="image/*" @change="handleFileInput" style="display: none" ref="imageUpload" />

        <div v-if="!hasMore" class="is-size-7 has-text-grey-light has-text-centered mt-2 mb-3">{{$t('event.noMoreImages')}}</div>
        <div class="is-size-7 has-text-grey-light has-text-centered mt-2 mb-3" v-show="loading">{{$t('system.loading')}}</div>
        
      </section>
      <section v-else class="add-image-prompt-section">
        <div class="image-container">
          <div class="image-wrapper">
            <img :src="uploadBlob" alt="Preview" class="preview-image" />
          </div>
          <b-field class="description-field">
            <b-input v-model="uploadDescription" type="textarea" rows="2" :placeholder="$t('event.addDescription')"
              maxlength="140" />
          </b-field>
          <div class="actions">
            <b-button :label="$t('system.close')" type="is-primary" outlined expanded @click="FinishImageUpload()"
              :disabled="isUploading" />
            <b-button :label="$t('system.save')" type="is-primary" expanded @click="HandleImageUpload()"
              :loading="isUploading" />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Compressor from "compressorjs";

export default {
  components: {
  },
  data() {
    return {
      // imagesByDate: {},
      media: [],
      page: 1,
      loading: false,
      hasMore: true,
      cooldownActive: false,
      scrollThreshold: 100, // pixels from bottom to trigger load

      showUploadModal: false,
      uploadBlob: null,
      uploadFile: null,
      uploadDescription: "",
      isUploading: false,
    };
  },
  methods: {
    GetItemThumbnail(item) {
      switch (item.media.relationTo) {
        case "user-images":
          return (
            item.media.value.sizes.thumbnail.url ||
            item.media.value.sizes.high.url
          );
        case "user-videos":
          return item.media.value.thumbnail.sizes.thumbnail.url;
      }
    },
    handleAddImage() {
      // Handle the add image click event
      this.$refs.imageUpload.click();
    },
    handleFileInput(event) {
      // Handle the image upload event
      this.showUploadModal = true;
      this.uploadFile = event.target.files[0];
      this.uploadBlob = URL.createObjectURL(event.target.files[0]);
    },
    FinishImageUpload() {
      this.showUploadModal = false;
      this.uploadBlob = null;
      this.uploadFile = null;
      this.uploadDescription = "";
      this.isUploading = false;
    },
    async CompressImage(file) {
      console.log("Compressing image");
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 2000,
          retainExif: true,
          success(result) {
            resolve(result);
          },
          error(err) {
            console.log(err.message);
            reject(err);
          },
        });
      });
    },
    async HandleImageUpload() {
      try {
        this.isUploading = true;
        const formData = new FormData();
        const imageFile = await this.CompressImage(this.uploadFile);
        formData.append("file", imageFile, imageFile.name);
        formData.append("description", this.uploadDescription);
        formData.append("createdBy", this.$store.state.user.profile.id);
        const photofeedRes = await axios.post(
          process.env.VUE_APP_API_BASE + "/api/photofeed",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(photofeedRes.data)
        this.$store.dispatch("event/AgregarMediaPhotofeed", photofeedRes.data.doc);
        this.media.unshift(photofeedRes.data.doc)
        this.$TrackUserEvent("Uploaded Photofeed Image", {
          imageId: photofeedRes.data.doc.id,
        });
      } catch (error) {
        console.error("Error uploading image:", error);
        this.$buefy.notification.open({
          type: "is-danger",
          message: this.$t('event.uploadError'),
        });
      } finally {
        this.FinishImageUpload();
      }
    },
    async removeImage(imageId) {
      console.log("Remove image", imageId)
      this.media = this.media.filter((img) => img.id !== imageId);
      await this.$store.dispatch("event/RemoveFromPhotofeed", imageId)
    },
    GoToMenu() {
      this.$router.push("/");
    },
    // Debounce function to prevent rapid firing
    debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },

    // Handle scroll event
    handleScroll() {
      const element = this.$refs.photofeed;
      const bottom =
        element.scrollHeight - element.clientHeight - element.scrollTop;

      if (
        bottom < this.scrollThreshold &&
        !this.loading &&
        !this.cooldownActive &&
        this.hasMore
      ) {
        this.loadMoreImages();
      }
    },

    async LoadAllImages() {
      try {
        if(this.allMedia == null){
          this.loading = true;
          await this.$store.dispatch("event/LoadPhotofeed");
        }
        // Espero a que los photofeed esten cargados
        while (this.allMedia == null) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        this.media = this.allMedia.slice(0, 14);
      } catch (error) {
        console.error("Error loading images:", error);
        this.$buefy.notification.open({
          type: "is-primary",
          message: this.$t('event.loadError'),
        });
      } finally {
        this.loading = false;
        this.$store.commit("StopLoading");
        this.handleScroll();
      }
    },
    // API call with cooldown
    async loadMoreImages() {
      if (this.loading || this.cooldownActive) return;

      console.log("Fetching images...");
      try {
        this.loading = true;
        // Delay for 1-3 second
        const delayTime = Math.random() * 3000
        await new Promise((resolve) => setTimeout(resolve, delayTime));
        const lastImage = this.media[this.media.length - 1];
        const lastImageIndex = this.allMedia.findIndex((img) => img.id === lastImage.id);
        const nextImages = this.allMedia.slice(lastImageIndex + 1, lastImageIndex + 13);
        this.media = [...this.media, ...nextImages];

        this.hasMore = this.media.length < this.allMedia.length;
        this.$forceUpdate();

        // Activate cooldown
        this.cooldownActive = true;
        setTimeout(() => {
          this.cooldownActive = false;
          this.handleScroll(); // despues del cooldown revisar si estamos al final para volver a cargar imagenes
        }, 2000); // x second cooldown
      } catch (error) {
        console.error("Error loading images:", error);
        this.$buefy.notification.open({
          type: "is-primary",
          message: this.$t('event.loadError'),
        });
      } finally {
        this.loading = false;
        this.$store.commit("StopLoading");
      }
    },
    AgregarImagen(image){
      this.media.unshift(image);
      // filter duplicate
      this.media = this.media.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.id === item.id
        ))
      )
    },
    openImage(id) {
      this.$router.push(`/photofeed/${id}`);
    },
  },
  computed: {
    allMedia() {
      return this.$store.state.event.photofeed;
    },
    backgroundImage() {
      if (this.$store.state.setup.headerBackground)
        return `background-image: url(${this.$store.state.setup.headerBackground.url})`;
      return null;
    },
  },
  mounted() {
    this.$socket.client.emit("player:JoinSpace", { spaceSlug: "photofeed" },() => {})
    // Force load favorites
    this.$store.dispatch("user/LoadFavorites");
    // Initial load
    // this.loadMoreImages();
    this.LoadAllImages();
    // Add scroll listener with debounce
    this.$refs.photofeed.addEventListener(
      "scroll",
      this.debounce(this.handleScroll, 20)
    );
  },
  beforeMount() {
    if (!this.$store.state.setup.event.photofeed?.enabled) {
      console.log("Acceso restringido");
      this.$router.push("/");
    }
  },
  beforeDestroy() {
    // Clean up scroll listener
    this.$refs.photofeed.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.photofeed {
  overflow-y: auto;
}

.notificationContainer {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background: white;
  border-radius: $general-border-radius;
}

// Home menu
.home-menu-header {
  align-items: center;
  justify-content: center;
  background-color: $home-menu-header;
  background-size: auto 100%;
  color: $black-bis;
}

.logo {
  max-height: 100%;
  max-height: 50px;
}

.title {
  font-size: 1.2em;
  font-weight: bolder;
  line-height: 1.2;
  text-align: center;
}

.photofeed-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5em;
}

.photofeed-item {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  border-radius: $general-border-radius;
  aspect-ratio: 1;
  background-color: #f5f5f5;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;

    span {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

.photofeed-empty-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  background: #f5f5f5;
  color: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $general-border-radius;
}
.add-image-prompt-section{
  height: 100%;
  .image-container{
    height: 100%;
  }
}
.add-image-container {
  aspect-ratio: 1;
  cursor: pointer;
}

.add-image-button {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  gap: 0.25em;
  border-radius: $general-border-radius;
  transition: background-color 0.2s;

  label {
    text-align: center;
    font-size: 0.9em;
    line-height: 1.1;
  }
}

.plus-icon {
  font-size: 24px;
  margin-bottom: 4px;
}

.image-container {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.image-wrapper {
  flex: 1;
  min-height: 0;
  position: relative;
  background-color: #F5F5F5;
}

.preview-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  display: block;
}

.actions {
  padding: 1rem;
}

:deep(.textarea) {
  border: none !important;
  box-shadow: none !important;
  resize: none;
}

:deep(.help.counter) {
  position: absolute;
  bottom: .5rem;
  right: .5rem;
  color: #4a4a4a;
}
</style>
